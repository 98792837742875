.patient-details {
   display: flex;
   align-items: center;
   justify-content: space-around;
   background: #FCFBF7;;
   border-radius: 8px;
   height: 105px;
}

.link-like-button {
   background: none!important;
   border: none;
   padding: 0!important;
   text-decoration: underline;
   cursor: pointer;
}

.tasks-card-container>.ant-card {
   width: 100%;
}

.tasks-card-container {
   width: inherit;
}

.react-kanban-column {
   min-width: 400px;
   width: inherit;
   height: auto !important;
   min-height: 150px !important;
   margin-top: 5px;
   background-color: #f2f2f2;
   border-radius: 5px;
   padding: 10px;
}

.react-kanban-board>div:first-child {
   width: 100%;
   display: flex;
   justify-content: space-evenly;
}

.react-kanban-board .react-kanban-column:first-child {
   margin-right: 10px;
}
.react-kanban-board .react-kanban-column:last-child {
   margin-left: 10px;
}

.react-kanban-board {
   display: flex;
   overflow-y: scroll !important;
   align-items: flex-start;
   width: 100%;
   max-height: calc(100vh - 300px);
}

[data-rbd-droppable-context-id="1"] {
   overflow-anchor: none;
}

.card-head-title-wrapper {
   display: flex;
   align-items: center;
}

.card-head-title {
   color: #000000d9;
   font-weight: 600;
   font-size: 20px;
   line-height: 32px;
   overflow: hidden;
   white-space: nowrap;
   text-overflow: ellipsis;
}

.card-head-description {
   font-family: Poppins;
   font-weight: 400;
   font-size: 13px;
   line-height: 19px;
   /* identical to box height */


   color: #1E406A;
}

.card-head-icon {
   margin-left: 13px;
}


.sort-btn-container {
   display: flex;
   align-items: center;
}

.column-status {
   display: flex;
   align-items: center;
   justify-content: space-between;
}

.sort-btn-text {
   font-family: Poppins;
   font-style: normal;
   font-weight: 500;
   font-size: 15px;
   line-height: 22px;
   /* identical to box height */
   color: #1E406A;
   opacity: 0.3;
   text-transform: capitalize;
}

.sort-btn-icon {
   margin-left: 20px;
}
.card-head-action-container{
   display: flex;
   align-items: center;
}
.btn-refresh{
   margin-left: 10px;
   padding: 0 !important;
}
.btn-first{
   margin-right: 10px;
}
.btn-refresh svg {
   fill: #FFFFFF;
}
.column-header{
   margin-bottom: 20px;
}